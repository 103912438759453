:root {
    --chat--color-primary: #e74266;
    --chat--color-primary-shade-50: #db4061;
    --chat--color-primary-shade-100: #cf3c5c;
    --chat--color-secondary: #20b69e;
    --chat--color-secondary-shade-50: #1ca08a;
    --chat--color-white: #ffffff;
    --chat--color-light: #f2f4f8;
    --chat--color-light-shade-50: #e6e9f1;
    --chat--color-light-shade-100: #c2c5cc;
    --chat--color-medium: #d2d4d9;
    --chat--color-dark: #283266;
    --chat--color-disabled: #777980;
    --chat--color-typing: #404040;
  
    --chat--spacing: 1rem;
    --chat--border-radius: 0.25rem;
    --chat--transition-duration: 0.15s;
  
    --chat--window--width: 400px;
    --chat--window--height: 600px;
  
    --chat--header-height: auto;
    --chat--header--padding: var(--chat--spacing);
    --chat--header--background: var(--chat--color-dark);
    --chat--header--color: var(--chat--color-light);
    --chat--header--border-top: none;
    --chat--header--border-bottom: none;
    --chat--heading--font-size: 1.5em;
    --chat--subtitle--font-size: inherit;
    --chat--subtitle--line-height: 0.5;
  
    --chat--textarea--height: 70px;
  
    --chat--message--font-size: 0.9rem;
    --chat--message--padding: var(--chat--spacing);
    --chat--message--border-radius: var(--chat--border-radius);
    --chat--message-line-height: 1.8;
    --chat--message--bot--background: var(--chat--color-white);
    --chat--message--bot--color: var(--chat--color-dark);
    --chat--message--bot--border: none;
    --chat--message--user--background: var(--chat--color-secondary);
    --chat--message--user--color: var(--chat--color-white);
    --chat--message--user--border: none;
    --chat--message--pre--background: rgba(0, 0, 0, 0.05);
  
    --chat--toggle--background: var(--chat--color-primary);
    --chat--toggle--hover--background: var(--chat--color-primary-shade-50);
    --chat--toggle--active--background: var(--chat--color-primary-shade-100);
    --chat--toggle--color: var(--chat--color-white);
    --chat--toggle--size: 64px;
  }